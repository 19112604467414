<template>
  <div>
    <col-linea
      style="margin-top: -10px !important;"
      class="mb-2"
    />
    <!-- TITLE -->
    <b-row class="ml-25 mr-25">
      <b-col
        cols="12"
        class="mb-2 text-left"
      >
        <h5 class="mb-0">
          Estudiante
        </h5>
        <small class="text-muted">
          Ingresa la información del estudiante.
        </small>
      </b-col>
    </b-row>

    <!-- FORM -->
    <b-overlay
      :show="cargando"
      spinner-variant="primary"
      variant="#000000"
    >
      <b-row class="ml-25 mr-25 mb-3">
        <b-col
          cols="12"
          md="2"
          class="mb-2 mt-2"
        >
          <b-card-sub-title>
            Información personal
          </b-card-sub-title>
        </b-col>
        <b-col
          cols="12"
          md="10"
          class="mb-1 mt-2"
        >
          <b-card-sub-title >
            <hr class="mt-75">
          </b-card-sub-title>
        </b-col>

        <b-col
          cols="6"
        >
          <b-row>

            <!-- TIPO IDENTIFICACION -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Tipo identificación *"
                label-for="tipo_identificacion"
              >
                <v-select
                  v-model="estudiante.tipo_identificacion"
                  placeholder="Selecciona el tipo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.tipo_identificacion"
                  :options="optionsTipoIdentificacion"
                  :class="v$.estudiante.tipo_identificacion.$error === true
                    ? 'border-danger rounded'
                    : ''"
                  @input="changeTipoIdentificacion()"
                />
                  <!-- :disabled="optionsPaises.length === 0" -->
                <div
                  v-if="v$.estudiante.tipo_identificacion.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.tipo_identificacion.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- RUT -->
            <b-col
              v-if="estudiante.tipo_identificacion === 1"
              cols="12"
              md="12"
            >
              <b-form-group
                label="Rut *"
                label-for="rut"
              >
                <b-form-input
                  id="rut"
                  v-model="estudiante.rut"
                  placeholder="Ingrese un rut"
                  :state="v$.estudiante.rut.$error === true
                  ? false
                  : null"
                  @blur.native="v$.estudiante.rut.$touch"
                  @keyup="estudiante.rut = formatRut(estudiante.rut)"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.rut.$error"
                  id="rutInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.estudiante.rut.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- IPE -->
            <b-col
              v-else-if="estudiante.tipo_identificacion === 2"
              cols="12"
              md="12"
            >
              <b-form-group
                label="IPE *"
                label-for="ipe"
              >
                <b-form-input
                  id="ipe"
                  v-model="estudiante.ipe"
                  placeholder="01122000"
                  :state="v$.estudiante.ipe.$error === true
                  ? false
                  : null"
                  @blur.native="v$.estudiante.ipe.$touch"
                  @keyup="estudiante.ipe = formatRut(estudiante.ipe)"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.ipe.$error"
                  id="rutInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.estudiante.ipe.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- NOMBRE -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Primer nombre *"
                label-for="nombre"
              >
                <b-form-input
                  id="nombre"
                  v-model="estudiante.nombre"
                  placeholder="Ingresa el primer nombre"
                  :state="v$.estudiante.nombre.$error === true
                  ? false
                  : null"
                  @blur.native="v$.estudiante.nombre.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.nombre.$error"
                  id="nombreInfo"
                >
                  <p
                    v-for="error of v$.estudiante.nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO NOMBRE -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Segundo nombre"
                label-for="segundo_nombre"
              >
                <b-form-input
                  id="segundo_nombre"
                  v-model="estudiante.segundo_nombre"
                  placeholder="Ingresa el segundo nombre"
                  :state="v$.estudiante.segundo_nombre.$error === true
                  ? false
                  : null"
                  @blur.native="v$.estudiante.segundo_nombre.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.segundo_nombre.$error"
                  id="segundo_nombreInfo"
                >
                  <p
                    v-for="error of v$.estudiante.segundo_nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- PRIMER APELLIDO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Primer apellido *"
                label-for="primer_apellido"
              >
                <b-form-input
                  id="primer_apellido"
                  v-model="estudiante.primer_apellido"
                  placeholder="Ingresa el primer apellido"
                  :state="v$.estudiante.primer_apellido.$error === true
                  ? false
                  : null"
                  @blur.native="v$.estudiante.primer_apellido.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.primer_apellido.$error"
                  id="primer_apellidoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.primer_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO APELLIDO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Segundo apellido *"
                label-for="segundo_apellido"
              >
                <b-form-input
                  id="segundo_apellido"
                  v-model="estudiante.segundo_apellido"
                  placeholder="Ingresa el segundo apellido"
                  :state="v$.estudiante.segundo_apellido.$error === true
                  ? false
                  : null"
                  @blur.native="v$.estudiante.segundo_apellido.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.segundo_apellido.$error"
                  id="segundo_apellidoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.segundo_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- FECHA DE NACIMIENTO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Fecha de nacimiento *"
                label-for="fecha_nacimiento"
              >
                <flat-pickr
                  v-model="estudiante.fecha_nacimiento"
                  class="form-control"
                  :config="config.locale"
                  placeholder="Selecciona una fecha"
                  name="date"
                  :class="v$.estudiante.fecha_nacimiento.$error === true
                    ? 'form-control border-danger rounded'
                    : 'form-control'"
                />
                <!-- Validaciones -->
                <div
                  v-if="v$.estudiante.fecha_nacimiento.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.fecha_nacimiento.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- GENERO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Genero *"
                label-for="genero"
              >
                <v-select
                  v-model="estudiante.genero"
                  placeholder="Selecciona un genero"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.genero"
                  :options="optionsGeneros"
                  :class="v$.estudiante.genero.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsGeneros.length === 0" -->
                <div
                  v-if="v$.estudiante.genero.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.genero.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>

        <b-col
          cols="6"
        >
          <b-row>

            <!-- CORREO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Correo *"
                label-for="correo"
              >
                <b-input-group
                  :class="v$.estudiante.correo.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="correo"
                    v-model="estudiante.correo"
                    placeholder="Ingresa el correo electrónico"
                    :class="v$.estudiante.correo.$error === false
                      ? ''
                      : 'border-danger rounded-right'"
                    @blur.native="v$.estudiante.correo.$touch"
                    :state="v$.estudiante.correo.$error === true
                    ? false
                    : null"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.correo.$error"
                  id="correoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.correo.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- CELULAR -->
            <b-col
              class="mb-25"
              cols="12"
              md="12"
            >
              <b-form-group
                label="Celular *"
                label-for="celular"
              >
                <b-input-group
                  :class="v$.estudiante.celular.$error === false ? '' : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="celular"
                    v-model="estudiante.celular"
                    placeholder="Ingresa el número de celular"
                    :state="v$.estudiante.celular.$error === true
                    ? false
                    : null"
                    @keyup='estudiante.celular = formatSoloNumerosMaxLenght(estudiante.celular, 11)'
                    @blur.native="v$.estudiante.celular.$touch"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.celular.$error"
                  id="celularInfo"
                >
                  <p
                    v-for="error of v$.estudiante.celular.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SUB TITULO: Direcciones -->
            <b-col
              cols="12"
              md="2"
              class="mb-2 mt-3"
            >
              <b-card-sub-title>
                Dirección
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="12"
              md="10"
              class="mb-1 mt-3"
            >
              <b-card-sub-title >
                <hr class="mt-75">
              </b-card-sub-title>
            </b-col>

            <!-- COMUNA -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Comuna *"
                label-for="id_comuna"
              >
                <v-select
                  v-model="estudiante.id_comuna"
                  placeholder="Selecciona una comuna..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :reduce="option => option.id_comuna"
                  :options="optionsComunas"
                  :class="v$.estudiante.id_comuna.$error === true
                    ? 'border-danger rounded'
                    : ''"
                  :disabled="optionsComunas.length === 0"
                />
                <div
                  v-if="v$.estudiante.id_comuna.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.id_comuna.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- CALLE -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Calle *"
                label-for="nombre_calle"
              >
                <b-form-input
                  id="nombre_calle"
                  v-model="estudiante.nombre_calle"
                  placeholder="Av. General Borgoño"
                  :state="v$.estudiante.nombre_calle.$error === true
                  ? false
                  : null"
                  @blur.native="v$.estudiante.nombre_calle.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.nombre_calle.$error"
                  id="nombre_calleInfo"
                >
                  <p
                    v-for="error of v$.estudiante.nombre_calle.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- NUMERO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Numeración *"
                label-for="numero"
              >
                <b-input-group
                  :class="v$.estudiante.numero.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    #
                  </b-input-group-prepend>
                  <b-form-input
                    id="numero"
                    v-model="estudiante.numero"
                    placeholder="Ingresa el número de la dirección"
                    :state="v$.estudiante.numero.$error === true
                    ? false
                    : null"
                    @keyup='estudiante.numero = formatSoloNumerosMaxLenght(estudiante.numero, 8)'
                    @blur.native="v$.estudiante.numero.$touch"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.numero.$error"
                  id="numeroInfo"
                >
                  <p
                    v-for="error of v$.estudiante.numero.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- BLOCK -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Block"
                label-for="block"
              >
                <b-form-input
                  id="block"
                  v-model="estudiante.block"
                  placeholder="Ingresa el block"
                  :state="v$.estudiante.block.$error === true
                    ? false
                    : null"
                  @blur.native="v$.estudiante.block.$touch"
                />
                  <!-- @keyup='estudiante.block = formatSoloNumerosMaxLenght(estudiante.block, 8)' -->

                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.block.$error"
                  id="blockInfo"
                >
                  <p
                    v-for="error of v$.estudiante.block.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- DEPARTAMENTO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Departamento"
                label-for="departamento"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    #
                  </b-input-group-prepend>
                  <b-form-input
                    id="departamento"
                    v-model="estudiante.departamento"
                    placeholder="Ingresa el número de departamento"
                    @keyup='estudiante.departamento = formatSoloNumerosMaxLenght(estudiante.departamento, 8)'
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>

        </b-col>


        <!-- SUB TITULO: Información escolar -->
        <b-col
          cols="12"
          md="2"
          class="mb-2 mt-3"
        >
          <b-card-sub-title>
            Información escolar
          </b-card-sub-title>
        </b-col>
        <b-col
          cols="12"
          md="10"
          class="mb-1 mt-3"
        >
          <b-card-sub-title >
            <hr class="mt-75">
          </b-card-sub-title>
        </b-col>

        <!-- CURSO -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Curso *"
            label-for="id_cursos"
          >
            <v-select
              v-model="estudiante.id_cursos"
              placeholder="Selecciona el curso..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :reduce="option => option.id_cursos"
              :options="optionsCursos"
              :class="v$.estudiante.id_cursos.$error === true
                ? 'border-danger rounded'
                : ''"
              :disabled="true"
              @input="changeTipoIdentificacion()"
            />
              <!-- :disabled="optionsPaises.length === 0" -->
            <div
              v-if="v$.estudiante.id_cursos.$error"
              id="asistentesInfo"
              class="text-danger text-left"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.estudiante.id_cursos.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </b-form-group>
        </b-col>

        <!-- FECHA INGRESO -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Fecha Ingreso *"
            label-for="fecha_ingreso"
          >
            <b-form-datepicker
              id="fecha_ingreso"
              v-model="estudiante.fecha_ingreso"
              placeholder="Abrir calendario"
              hide-header
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              start-weekday="1"
              locale="es-CL"
              label-help=""
            />
            <!-- Validaciones -->
            <b-form-invalid-feedback
              v-if="v$.estudiante.fecha_ingreso.$error"
              id="fecha_ingresoInfo"
            >
              <p
                v-for="error of v$.estudiante.fecha_ingreso.$errors"
                :key="error.$uid"
              >
                {{ error.$message }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- PROCEDENCIA -->
        <!-- <b-col
          cols="12"
          md="12"
          class="mb-2"
        >
          <b-form-group
            label="Procedencia *"
            label-for="procedencia"
          >
            <b-form-input
              id="procedencia"
              v-model="estudiante.procedencia"
              placeholder="Ingresa la procedenia"
            />
          </b-form-group>
        </b-col> -->

        <!-- BENEFICIO -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Beneficio Chile Solidario o Puente"
            label-for="beneficio"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.beneficio"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- AUTORIZACION -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Autorización para almorzar afuera"
            label-for="autorizacion"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.autorizacion"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- PRIORITARIO -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Estudiante prioritario"
            label-for="prioritario"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.prioritario"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- JUNAEB -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Junaeb"
            label-for="junaeb"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.junaeb"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- RELIGION -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Se autoriza a cursar religión"
            label-for="religion"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.religion"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- PIE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Programa de integración escolar (PIE)"
            label-for="pie"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.pie"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- TRANSPORTE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Transporte escolar"
            label-for="transporte"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.transporte"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- REPITENCIA -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Repitencia"
            label-for="repitencia"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.repitencia"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- APRENDIZAJE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Problema de aprendizaje"
            label-for="aprendizaje"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="estudiante.aprendizaje"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Action Buttons -->
      <col-linea
        style="margin-left: -20px !important; margin-right: -20px !important;"
      />
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
        </b-col>
        <b-col
          class="text-right"
          cols="12"
          md="4"
        >
          <b-button-group>
            <btnSubmit
              class="rounded mr-1"
              variant="primary"
              btnText="Guardar y salir"
              modulo="matriculas"
              @processBtn="submitOption(2)"
            />
            <btnSubmit
              class="rounded"
              variant="primary"
              btnText="Guardar"
              modulo="matriculas"
              @processBtn="submitOption(1)"
            />
          </b-button-group>
        </b-col>
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader,
  BCardTitle, BFormCheckbox, BCardSubTitle, BInputGroup, BInputGroupPrepend,
  BFormDatepicker, BFormInvalidFeedback, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapActions, mapGetters } from 'vuex'

import store from '@/store/index'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// Componentes reciclados
import colLinea from '../../../components/Form/colLinea.vue';
import btnSubmit from '../../../components/Form/btnSubmit.vue';

export default {
  components: {
    BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader,
    BCardTitle, BFormCheckbox, BCardSubTitle, BInputGroup, BInputGroupPrepend,
    BFormDatepicker, BFormInvalidFeedback, BButtonGroup, BOverlay,
    vSelect,
    flatPickr,

    // Componentes reciclados
    colLinea,
    btnSubmit,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      cargando: true,
      id_establecimiento_user: null,
      estudiante: [],
      // otros
      config: {
        time: null,
        wrap: true,
        noCalendar: false,
        locale: {
          locale: SpanishLocale,
        },
        showMonths: 1, // sirve para un desde hasta, muestra más calendarios
      },
      // options
      optionsComunas: [],
      optionsCursos: [],
      optionsGeneros: [
        {
          genero: 1,
          title: 'Masculino',
        },
        {
          genero: 2,
          title: 'Femenino',
        },
        {
          genero: 3,
          title: 'Otro',
        },
      ],
      optionsTipoIdentificacion: [
        {
          tipo_identificacion: 1,
          title: 'RUT',
        },
        {
          tipo_identificacion: 2,
          title: 'IPE',
        }
      ],
    }
  },
  watch: {
    user (val){
      this.id_establecimiento_user = val.id_establecimiento
    },
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getPaises: 'ceds/getPaises',
      getComunas: 'ceds/getComunas',
      getPersona: 'personas/getPersona',
      getCursos: 'cursos/getCursos',
      getMatricula: 'matriculas/getMatricula',
    }),
  },
  mounted() {
    this.cargaUser()
    this.cargaPaises()
    this.cargaComunas()
    this.cargarEstudiante()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchPaises: 'ceds/fetchPaises',
      fetchComunas: 'ceds/fetchComunas',
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',

      fetchMatricula: 'matriculas/fetchMatricula',
      addMatricula: 'matriculas/addMatricula',
    }),
    cargaUser() {
      if (this.id_establecimiento_user === null) {
        this.attempt().then(() => {
          this.cargaCursos()
        })
      } else {
        this.cargaCursos()
      }
    },
    cargaPaises() {
      this.fetchPaises().then(() => {
        this.optionsPaises = []
        this.getPaises.forEach(pais => {
          this.optionsPaises.push({
            id_pais: pais.RefCountryId,
            nombre: pais.Description,
          })
        })
      })
    },
    cargaComunas() {
      this.fetchComunas().then(() => {
        this.optionsComunas = []
        this.getComunas.forEach(comuna => {
          this.optionsComunas.push({
            id_comuna: comuna.RefCountyId,
            nombre: comuna.Description,
          })
        })
      })
    },
    cargaCursos() {
      this.fetchCursosEstablecimiento(this.id_establecimiento_user).then(() => {
        this.optionsCursos = []
        this.getCursos.forEach(curso => {
          const nombre = curso.nombre+' '+ curso.letra
          this.optionsCursos.push({
            id_cursos: curso.id,
            title: nombre,
          })
        })
      })
    },
    cargarEstudiante() {
      const comuna = this.getComunas.find(c => c.RefCountyId === this.matricula.id_comuna)
      const id_comuna = typeof comuna === 'undefined' ? null : this.matricula.id_comuna
      this.estudiante = {
        // persona
        tipo_identificacion: this.matricula.tipo_identificacion,
        rut: this.matricula.rut_completo,
        dv: this.matricula.dv,
        nombre: this.matricula.nombre,
        segundo_nombre: this.matricula.segundo_nombre,
        primer_apellido: this.matricula.primer_apellido,
        segundo_apellido: this.matricula.segundo_apellido,
        genero: this.matricula.genero,
        fecha_nacimiento: this.matricula.fecha_nacimiento,

        // apoderado
        id_persona_rol_apoderado: this.matricula.id_persona_rol_apoderado,
        id_persona_rol_apoderado_suplente: this.matricula.id_persona_rol_apoderado_suplente,

        // dirección
        nombre_calle: this.matricula.nombre_calle,
        numero: this.matricula.numero,
        celular: this.matricula.celular,
        correo: this.matricula.correo,
        id_comuna,
        id_pais: this.matricula.id_pais,

        // antecedentes escolares
        fecha_ingreso: this.matricula.fecha_ingreso,
        id_establecimiento: this.matricula.id_establecimiento,
        id_cursos: this.matricula.id_cursos,
        procedencia: this.matricula.procedencia,
        repitencia: this.matricula.repitencia === 1,
        prioritario: this.matricula.prioritario === 1,
        beneficio: this.matricula.beneficio === 1,
        pie: this.matricula.pie === 1,
        religion: this.matricula.religion === 1,
        junaeb: this.matricula.junaeb === 1,
        autorizacion: this.matricula.autorizacion === 1,
        aprendizaje: this.matricula.aprendizaje === 1,
        transporte: this.matricula.transporte === 1,
      }
      this.cargando = false
    },
    submitOption(tipo) {
      this.v$.estudiante.$touch()
      if (!this.v$.estudiante.$invalid) {
        const rut = this.divideRut(this.estudiante.rut) // mixin format
        const data = {
          // persona
          tipo_identificacion: this.estudiante.tipo_identificacion,
          rut: rut.rutSinDv,
          dv: rut.dv,
          nombre: this.estudiante.nombre,
          primer_apellido: this.estudiante.primer_apellido,
          segundo_apellido: this.estudiante.segundo_apellido,
          genero: this.estudiante.genero,
          fecha_nacimiento: this.estudiante.fecha_nacimiento,

          // Apoderado
          id_persona_rol_apoderado: this.estudiante.id_persona_rol_apoderado,

          // dirección
          celular: this.estudiante.celular,
          correo: this.estudiante.correo,
          nombre_calle: this.estudiante.nombre_calle,
          numero: this.estudiante.numero,
          id_comuna: this.estudiante.id_comuna,
          id_pais: 45,

          // Antecedentes escolares
          id: this.estudiante.id_matricula,
          fecha_ingreso: this.estudiante.fecha_ingreso,
          repitencia: this.estudiante.repitencia,
          id_establecimiento: this.estudiante.id_establecimiento,
          id_cursos: this.estudiante.id_cursos,
          prioritario: this.estudiante.prioritario,
          beneficio: this.estudiante.beneficio,
          pie: this.estudiante.pie,
          religion: this.estudiante.religion,
          junaeb: this.estudiante.junaeb,
          autorizacion: this.estudiante.autorizacion,
          aprendizaje: this.estudiante.aprendizaje,
          transporte: this.estudiante.transporte,
          procedencia: this.estudiante.procedencia,
        }

        this.addMatricula(data).then(() => {
          const statusMatriculas = store.state.matriculas.status
          if (statusMatriculas === 'success') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Estudiante guardado 👍',
                icon: 'CheckIcon',
                text: 'El estudiante fue editado con éxito!',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
              timeout: 3000,
            })
            if (tipo === 2) {
              this.$router.replace({
                name: 'matriculas',
              })
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: store.state.matriculas.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      }

    },

    changeTipoIdentificacion() {
      if (this.estudiante.tipo_identificacion === null) {
        this.estudiante.tipo_identificacion = 1
      }
    },
  },
  validations() {
    let estudiante = new Object()

    let rut_estudiante = new Object()
    let ipe_estudiante = new Object()

    if (this.estudiante.tipo_identificacion === 1) {
      rut_estudiante = {
        // $autoDirty: true,
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 13 caracteres.', maxLength(13)),
      }
    } else if (this.estudiante.tipo_identificacion === 2) {
      ipe_estudiante = {
        // $autoDirty: true,
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 8 caracteres.', maxLength(8)),
      }
    }

    return {
      estudiante: {
        rut: rut_estudiante,
        ipe: ipe_estudiante,
        nombre: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        segundo_nombre: {
          // $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primer_apellido: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundo_apellido: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        genero: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_nacimiento: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },

        nombre_calle: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        numero: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },


        tipo_identificacion: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_ingreso: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_comuna: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },

        block: {
          // $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 10 caracteres.', maxLength(10)),
        },

        correo: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          // $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
          required: helpers.withMessage('El campo es requerido.', required),
        },

        id_cursos: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
