<template>
  <b-card>
    <b-tabs
      class="primary"
      pills
    >
      <!-- ESTUDIANTE -->
      <b-tab lazy active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Estudiante</span>
        </template>
        <tab-estudiante
          class="mt-2 pt-75"
          :crud="crud"
          :matricula="matricula"
        />
      </b-tab>

      <!-- APODERADO -->
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Apoderado</span>
        </template>
        <tab-apoderado
          class="mt-2 pt-75"
          :crud="crud"
          :matricula="matricula"
        />
      </b-tab>

      <!-- FAMILIAR -->
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Núcleo Familiar</span>
        </template>
        <tab-familiar
          class="mt-2 pt-75"
          :crud="crud"
          :matricula="matricula"
        />
      </b-tab>

      <!-- SALUD -->
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Antecedentes de salud</span>
        </template>
        <tab-salud
          class="mt-2 pt-75"
          :crud="crud"
          :matricula="matricula"
        />
      </b-tab>

      <!-- ESCOLAR -->
      <!--<b-tab lazy>
        <template #title>
          <feather-icon
            icon="BookIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Antecedentes escolares</span>
        </template>
         <tab-escolar
          class="mt-2 pt-75"
          :crud="crud"
          :matricula="matricula"
        />
      </b-tab>-->

    </b-tabs>
  </b-card>
</template>

<script>

// Etiquetas //
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'

// Componentes hijos
import TabEstudiante from './MatriculasFormTabs/TabEstudiante.vue'
import TabApoderado from './MatriculasFormTabs/TabApoderado.vue'
import TabFamiliar from './MatriculasFormTabs/TabFamiliar.vue'
import TabSalud from './MatriculasFormTabs/TabSalud.vue'
// import TabEscolar from './MatriculasFormTabs/TabEscolar.vue'

// Componente reciclado
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    BCard, BTabs, BTab,

    // Componentes hijos
    TabEstudiante,
    TabApoderado,
    TabFamiliar,
    TabSalud,
    // TabEscolar,

    // Componente reciclado
    colLinea,
  },
  props: {
    matricula: {
      type: Object,
      required: false,
    },
    crud: {
      type: String,
      required: true,
    },
  },
}
</script>
