<template>
  <div
    v-if="selectedMatricula"
  >
      <matriculas-form
        crud="u"
        :matricula="selectedMatricula"
      />
  </div>
</template>

<script>

import { mapState } from 'vuex'

import MatriculasForm from './MatriculasForm.vue'

export default {
  components: {
    MatriculasForm,
  },
  computed: {
    ...mapState('matriculas', ['selectedMatricula']),
  },
}
</script>
