<template>
  <div>
    <col-linea
      style="margin-top: -10px !important;"
      class="mb-2"
    />

    <!-- TITULO -->
    <b-row class="ml-25 mr-25">
      <b-col
        cols="12"
        class="mb-2 text-left"
      >
        <h5 class="mb-0">
          Apoderado
        </h5>
        <small class="text-muted">
          Ingresa la información del apoderado.
        </small>
      </b-col>
    </b-row>

    <!-- FORM -->
    <b-overlay
      :show="cargando"
      spinner-variant="primary"
      variant="#000000"
    >
      <b-row class="ml-25 mr-25">
        <b-col
          cols="12"
          md="2"
          class="mb-2 mt-2"
        >
          <b-card-sub-title>
            Información personal
          </b-card-sub-title>
        </b-col>
        <b-col
          cols="12"
          md="10"
          class="mb-1 mt-2"
        >
          <b-card-sub-title >
            <hr class="mt-75">
          </b-card-sub-title>
        </b-col>
        <b-col
          cols="6"
        >
          <!-- INFO PERSONAL -->
          <b-row>

            <!-- RUT -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Rut *"
                label-for="rut"
              >
                <b-form-input
                  id="rut"
                  v-model="apoderado.rut"
                  placeholder="11.111.111-1"
                  :state="v$.apoderado.rut.$error === true
                  ? false
                  : null"
                  @blur.native="v$.apoderado.rut.$touch"
                  @keyup="apoderado.rut = formatRut(apoderado.rut)"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.rut.$error"
                  id="rutInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.apoderado.rut.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- NOMBRE -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Primer nombre *"
                label-for="nombre"
              >
                <b-form-input
                  id="nombre"
                  v-model="apoderado.nombre"
                  placeholder="Isidora"
                  :state="v$.apoderado.nombre.$error === true
                  ? false
                  : null"
                  @blur.native="v$.apoderado.nombre.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.nombre.$error"
                  id="nombreInfo"
                >
                  <p
                    v-for="error of v$.apoderado.nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO NOMBRE -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Segundo nombre"
                label-for="segundo_nombre"
              >
                <b-form-input
                  id="segundo_nombre"
                  v-model="apoderado.segundo_nombre"
                  placeholder="Paz"
                  :state="v$.apoderado.segundo_nombre.$error === true
                  ? false
                  : null"
                  @blur.native="v$.apoderado.segundo_nombre.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.segundo_nombre.$error"
                  id="segundo_nombreInfo"
                >
                  <p
                    v-for="error of v$.apoderado.segundo_nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- PRIMER APELLIDO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Primer apellido *"
                label-for="primer_apellido"
              >
                <b-form-input
                  id="primer_apellido"
                  v-model="apoderado.primer_apellido"
                  placeholder="Álvarez"
                  :state="v$.apoderado.primer_apellido.$error === true
                  ? false
                  : null"
                  @blur.native="v$.apoderado.primer_apellido.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.primer_apellido.$error"
                  id="primer_apellidoInfo"
                >
                  <p
                    v-for="error of v$.apoderado.primer_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO APELLIDO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Segundo apellido *"
                label-for="segundo_apellido"
              >
                <b-form-input
                  id="segundo_apellido"
                  v-model="apoderado.segundo_apellido"
                  placeholder="Rivera"
                  :state="v$.apoderado.segundo_apellido.$error === true
                  ? false
                  : null"
                  @blur.native="v$.apoderado.segundo_apellido.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.segundo_apellido.$error"
                  id="segundo_apellidoInfo"
                >
                  <p
                    v-for="error of v$.apoderado.segundo_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- FECHA DE NACIMIENTO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Fecha de nacimiento *"
                label-for="fecha_nacimiento"
              >
                <flat-pickr
                  v-model="apoderado.fecha_nacimiento"
                  class="form-control"
                  :config="config.locale"
                  placeholder="Selecciona una fecha"
                  name="date"
                  :class="v$.apoderado.fecha_nacimiento.$error === true
                    ? 'form-control border-danger rounded'
                    : 'form-control'"
                />
                <!-- Validaciones -->
                <div
                  v-if="v$.apoderado.fecha_nacimiento.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.apoderado.fecha_nacimiento.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- GENERO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Genero *"
                label-for="genero"
              >
                <v-select
                  v-model="apoderado.genero"
                  placeholder="Selecciona un genero"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.genero"
                  :options="optionsGeneros"
                  :class="v$.apoderado.genero.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsGeneros.length === 0" -->
                <div
                  v-if="v$.apoderado.genero.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.apoderado.genero.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
        <b-col
          cols="6"
        >
          <b-row>

            <!-- CORREO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Correo *"
                label-for="correo"
              >
                <b-input-group
                  :class="v$.apoderado.correo.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="correo"
                    v-model="apoderado.correo"
                    placeholder="paz.alv@gmail.com"
                    :class="v$.apoderado.correo.$error === false
                      ? ''
                      : 'border-danger rounded-right'"
                    @blur.native="v$.apoderado.correo.$touch"
                    :state="v$.apoderado.correo.$error === true
                    ? false
                    : null"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.correo.$error"
                  id="correoInfo"
                >
                  <p
                    v-for="error of v$.apoderado.correo.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- CELULAR -->
            <b-col
              class="mb-25"
              cols="12"
              md="12"
            >
              <b-form-group
                label="Celular *"
                label-for="celular"
              >
                <b-input-group
                  :class="v$.apoderado.celular.$error === false ? '' : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="celular"
                    v-model="apoderado.celular"
                    placeholder="56978717595"
                    :state="v$.apoderado.celular.$error === true
                    ? false
                    : null"
                    @keyup='apoderado.celular = formatSoloNumerosMaxLenght(apoderado.celular, 11)'
                    @blur.native="v$.apoderado.celular.$touch"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.celular.$error"
                  id="celularInfo"
                >
                  <p
                    v-for="error of v$.apoderado.celular.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SUB TITULO: Dirección -->
            <b-col
              cols="12"
              md="2"
              class="mb-2 mt-3"
            >
              <b-card-sub-title>
                Dirección
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="12"
              md="10"
              class="mb-1 mt-3"
            >
              <b-card-sub-title >
                <hr class="mt-75">
              </b-card-sub-title>
            </b-col>

            <!-- Nombre CALLE -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Calle *"
                label-for="nombre_calle"
              >
                <b-form-input
                  id="nombre_calle"
                  v-model="apoderado.nombre_calle"
                  placeholder="Av. General Borgoño"
                  :state="v$.apoderado.nombre_calle.$error === true
                  ? false
                  : null"
                  @blur.native="v$.apoderado.nombre_calle.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.nombre_calle.$error"
                  id="nombre_calleInfo"
                >
                  <p
                    v-for="error of v$.apoderado.nombre_calle.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- NUMERO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Numeración *"
                label-for="numero"
              >
                <b-input-group
                  :class="v$.apoderado.numero.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    #
                  </b-input-group-prepend>
                  <b-form-input
                    id="numero"
                    v-model="apoderado.numero"
                    placeholder="1305"
                    :state="v$.apoderado.numero.$error === true
                    ? false
                    : null"
                    @keyup='apoderado.numero = formatSoloNumerosMaxLenght(apoderado.numero, 8)'
                    @blur.native="v$.apoderado.numero.$touch"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.numero.$error"
                  id="numeroInfo"
                >
                  <p
                    v-for="error of v$.apoderado.numero.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- BLOCK -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Block"
                label-for="block"
              >
                <b-form-input
                  id="block"
                  v-model="apoderado.block"
                  placeholder="A"
                  :state="v$.apoderado.block.$error === true
                    ? false
                    : null"
                  @blur.native="v$.apoderado.block.$touch"
                />
                  <!-- @keyup='apoderado.block = formatSoloNumerosMaxLenght(apoderado.block, 8)' -->

                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.apoderado.block.$error"
                  id="blockInfo"
                >
                  <p
                    v-for="error of v$.apoderado.block.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- DEPARTAMENTO -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Departamento"
                label-for="departamento"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    #
                  </b-input-group-prepend>
                  <b-form-input
                    id="departamento"
                    v-model="apoderado.departamento"
                    placeholder="707"
                    @keyup='apoderado.departamento = formatSoloNumerosMaxLenght(apoderado.departamento, 8)'
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
      </b-row>

      <!-- Action Buttons -->
      <col-linea
        style="margin-left: -20px !important; margin-right: -20px !important;"
      />
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
        </b-col>
        <b-col
          class="text-right"
          cols="12"
          md="4"
        >
          <b-button-group>
            <btnSubmit
              class="rounded mr-1"
              variant="primary"
              btnText="Guardar y salir"
              modulo="matriculas"
              @processBtn="submitOption(2)"
            />
            <btnSubmit
              class="rounded"
              variant="primary"
              btnText="Guardar"
              modulo="matriculas"
              @processBtn="submitOption(1)"
            />
          </b-button-group>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup,
  BCardSubTitle, BInputGroup, BInputGroupPrepend, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// Componentes reciclados
import colLinea from '../../../components/Form/colLinea.vue';
import btnSubmit from '../../../components/Form/btnSubmit.vue';

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup,
    BCardSubTitle, BInputGroup, BInputGroupPrepend, BButtonGroup, BOverlay,
    vSelect,
    flatPickr,

    // Componentes reciclados
    colLinea,
    btnSubmit,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      cargando: true,
      id_establecimiento_user: null,
      apoderado: [],
      // otros
      config: {
        time: null,
        wrap: true,
        noCalendar: false,
        locale: {
          locale: SpanishLocale,
        },
        showMonths: 1, // sirve para un desde hasta, muestra más calendarios
      },
      // options
      optionsGeneros: [
        {
          genero: 1,
          title: 'Masculino',
        },
        {
          genero: 2,
          title: 'Femenino',
        },
        {
          genero: 3,
          title: 'Otro',
        },
      ],
    }
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getPaises: 'ceds/getPaises',
      getComunas: 'ceds/getComunas',
      getPersonasRol: 'personas/getPersonasRol',
    }),
  },
  watch: {
    user (val){
      this.id_establecimiento_user = val.id_establecimiento
    },
  },
  mounted() {
    this.cargaPaises()
    this.cargaComunas()
    this.cargaApoderado()
    this.cargaUser()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchPaises: 'ceds/fetchPaises',
      fetchComunas: 'ceds/fetchComunas',

      fetchIdPersonasRol: 'personas/fetchIdPersonasRol',
      createPersona: 'personas/addPersona',
      updatePersona: 'personas/updatePersona',
      addMatricula: 'matriculas/addMatricula',
    }),
    ...mapMutations('matriculas', ['setMatricula']),
    cargaUser() {
      if (!this.user) {
        this.attempt().then(() => {})
      } else {
        this.id_establecimiento_user = this.user.id_establecimiento
      }
    },
    cargaPaises() {
      this.fetchPaises().then(() => {
        this.optionsPaises = []
        this.getPaises.forEach(pais => {
          this.optionsPaises.push({
            id_pais: pais.RefCountryId,
            nombre: pais.Description,
          })
        })
      })
    },
    cargaComunas() {
      this.fetchComunas().then(() => {
        this.optionsComunas = []
        this.getComunas.forEach(comuna => {
          this.optionsComunas.push({
            id_comuna: comuna.RefCountyId,
            nombre: comuna.Description,
          })
        })
      })
    },
    cargaApoderado() {
      this.fetchIdPersonasRol(this.matricula.id_persona_rol_apoderado).then(() => {
        const rut_completo = this.formatRut(this.getPersonasRol.rut + '' + this.getPersonasRol.dv)
        this.apoderado = {
          // persona
          id_persona: this.getPersonasRol.id_persona,
          id_persona_rol: this.getPersonasRol.id_persona_rol,
          rut: rut_completo,
          dv: this.getPersonasRol.dv,
          nombre: this.getPersonasRol.nombre,
          segundo_nombre: this.getPersonasRol.segundo_nombre,
          primer_apellido: this.getPersonasRol.primer_apellido,
          segundo_apellido: this.getPersonasRol.segundo_apellido,
          genero: this.getPersonasRol.genero,
          fecha_nacimiento: this.getPersonasRol.fecha_nacimiento,

          // dirección
          celular: this.getPersonasRol.celular,
          correo: this.getPersonasRol.correo,
          nombre_calle: this.getPersonasRol.nombre_calle,
          numero:this.getPersonasRol.numero,
          block: this.getPersonasRol.block,
          departamento:this.getPersonasRol.departamento,

          // escolar
          id_rol: 11, // APODERADO
          id_establecimiento: this.id_establecimiento_user,
        }
        this.cargando = false
      })
    },
    submitOption(tipo) {
      this.v$.apoderado.$touch()
      if (!this.v$.apoderado.$invalid) {
        const rut = this.divideRut(this.apoderado.rut) // mixin format
        const data = {
          // persona
          id: this.apoderado.id_persona,
          rut: rut.rutSinDv,
          dv: rut.dv,
          nombre: this.apoderado.nombre,
          segundo_nombre: this.apoderado.segundo_nombre,
          primer_apellido: this.apoderado.primer_apellido,
          segundo_apellido: this.apoderado.segundo_apellido,
          celular: this.apoderado.celular,
          correo: this.apoderado.correo,
          genero: this.apoderado.genero,
          fecha_nacimiento: this.apoderado.fecha_nacimiento,

          nombre_calle: this.apoderado.nombre_calle,
          numero: this.apoderado.numero,
          block: this.apoderado.block,
          departamento: this.apoderado.departamento,

          id_rol: 11, // APODERADO
          id_establecimiento: this.id_establecimiento_user,
        }
        if (this.matricula.id_persona_rol_apoderado === 1 && this.apoderado.rut !== '11.111.111-1') {
          this.createPersona(data).then(() => {
            const id_persona_rol = store.state.personas.id_persona_rol
            if (id_persona_rol) {
              this.editarIdPersonaRolApoderado(id_persona_rol, tipo)
            } else {
              this.$swal({
                title: 'Error!',
                text: store.state.personas.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
        } else {
          this.updatePersona(data).then(() => {
            this.mensajeGuardado(tipo)
          })
        }
      }
    },
    editarIdPersonaRolApoderado(id_persona_rol_apoderado, tipo) {
      const data = {
        // persona
        tipo_identificacion: this.matricula.tipo_identificacion,
        rut: this.matricula.rut,
        dv: this.matricula.dv,
        nombre: this.matricula.nombre,
        primer_apellido: this.matricula.primer_apellido,
        segundo_apellido: this.matricula.segundo_apellido,
        genero: this.matricula.genero,
        fecha_nacimiento: this.matricula.fecha_nacimiento,

        // Apoderado
        id_persona_rol_apoderado,

        // dirección
        celular: this.matricula.celular,
        correo: this.matricula.correo,
        nombre_calle: this.matricula.nombre_calle,
        numero: this.matricula.numero,
        id_comuna: this.matricula.id_comuna,
        id_pais: 45,

        // Antecedentes escolares
        id: this.matricula.id_matricula,
        fecha_ingreso: this.matricula.fecha_ingreso,
        repitencia: this.matricula.repitencia,
        id_establecimiento: this.matricula.id_establecimiento,
        id_cursos: this.matricula.id_cursos,
        prioritario: this.matricula.prioritario,
        beneficio: this.matricula.beneficio,
        pie: this.matricula.pie,
        religion: this.matricula.religion,
        junaeb: this.matricula.junaeb,
        autorizacion: this.matricula.autorizacion,
        aprendizaje: this.matricula.aprendizaje,
        transporte: this.matricula.transporte,
        procedencia: this.matricula.procedencia,
      }
      this.addMatricula(data).then(() => {
        const statusMatriculas = store.state.matriculas.status
        if (statusMatriculas === 'success') {
          this.mensajeGuardado(tipo)
          this.matricula.id_persona_rol_apoderado = id_persona_rol_apoderado
          this.setMatricula(this.matricula)
        } else {
          this.$swal({
            title: 'Error!',
            text: store.state.matriculas.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    mensajeGuardado(tipo) {
      const statusPersonas = store.state.personas.status
      if (statusPersonas === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Apoderado guardado 👍',
            icon: 'CheckIcon',
            text: 'El apoderado fue editado con éxito!',
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
          timeout: 3000,
        })
        if (tipo === 2) {
          this.$router.replace({
            name: 'matriculas',
          })
        }
      }
      else {
        this.$swal({
          title: 'Error!',
          text: store.state.personas.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
  },
  validations() {
    return {
      apoderado: {
        rut: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 13 caracteres.', maxLength(13)),
        },
        nombre: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        segundo_nombre: {
          // $autoDirty: true,
          // required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primer_apellido: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundo_apellido: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        genero: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_nacimiento: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },

        nombre_calle: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        numero: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        block: {
          // $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 10 caracteres.', maxLength(10)),
        },
        correo: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),

          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          // $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
          required: helpers.withMessage('El campo es requerido.', required),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
